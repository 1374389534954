import {format, isAfter, isToday, parseISO, isBefore} from 'date-fns';
import {OrderTypes} from '@hy-vee/shared-utils-aisles-online';

import {getReturnUrlFromParent} from '../iframe-helpers';
import {LocationEcommerceStatus} from '../../../autogen/globalTypes';

export const fulfillmentDisplayTypes = {
    [OrderTypes.COURIER]: 'Courier',
    [OrderTypes.DELIVERY]: 'Delivery',
    [OrderTypes.PICKUP]: 'Pickup',
    [OrderTypes.PICKUP_LOCKER]: 'Pickup',
    [OrderTypes.PICKUP_POINT]: 'Pickup'
};

export const getFulfillmentTimeToCompare = (fulfillmentTime, reservationStepperFulfillmentTime) => {
    if (reservationStepperFulfillmentTime) {
        return Number(reservationStepperFulfillmentTime.fulfillmentTimeId) === Number(fulfillmentTime.fulfillmentTimeId)
            ? reservationStepperFulfillmentTime
            : fulfillmentTime;
    }

    return fulfillmentTime;
};

export const isFulfillmentTimeSelected = (fulfillmentTime, cart, reservationStepperFulfillmentTime) => {
    const fulfillmentTimeToCompare = getFulfillmentTimeToCompare(fulfillmentTime, reservationStepperFulfillmentTime);

    const startWindowEqual = cart.fulfillmentWindowStart === fulfillmentTimeToCompare.windowStart;
    const endWindowEqual = cart.fulfillmentWindowEnd === fulfillmentTimeToCompare.windowEnd;
    const hasNotExpired = isBefore(new Date(), parseISO(cart.fulfillmentExpiration));
    const isSameFulfillmentId = cart.fulfillmentId === fulfillmentTimeToCompare.fulfillmentScheduleId;

    return Boolean(startWindowEqual && endWindowEqual && hasNotExpired && isSameFulfillmentId);
};

export const isReservationStepperTimeSelected = (fulfillmentTime, reservationStepperFulfillmentTime) =>
    Boolean(reservationStepperFulfillmentTime) &&
    Number(fulfillmentTime.fulfillmentTimeId) === Number(reservationStepperFulfillmentTime.fulfillmentTimeId);

export const dayOfWeek = (day, unit = 'iii') => {
    if (isToday(parseISO(day.date))) {
        return 'Today';
    }

    return format(parseISO(day.date), unit);
};

export const monthAndDay = (day) => format(parseISO(day.date), 'MMM dd');

export const hasActiveReservation = ({fulfillmentId, fulfillmentExpiration}) =>
    fulfillmentId > 0 && isBefore(new Date(), parseISO(fulfillmentExpiration));

export const getCurrentFulfillmentTimeSlot = (cart) => {
    return cart.fulfillmentLocation.fulfillmentTimes.find(
        (fulfillmentTime) =>
            fulfillmentTime.windowStart === cart.fulfillmentWindowStart &&
            fulfillmentTime.windowEnd === cart.fulfillmentWindowEnd
    );
};

export const pickupLocationHasPasscode = (pickupLocation) => (pickupLocation ? pickupLocation.hasPasscode : false);

export const shouldAutomaticallySwitchLocations = (cartItems, pickupLocation) =>
    cartItems.length === 0 && !pickupLocationHasPasscode(pickupLocation);

export const getFulfillmentChangePageUrl = (fulfillmentLocationId, deliveryAddressId = null) => {
    const returnUrlFromParent = getReturnUrlFromParent();

    return `/aisles-online/fulfillment-change?changeFulfillmentLocationTo=${fulfillmentLocationId}${
        deliveryAddressId ? `&changeDeliveryAddressTo=${deliveryAddressId}` : ''
    }&returnUrl=${returnUrlFromParent}`;
};

export const allProductsAreLockerEligible = (products, pickupLocation) =>
    products.every(
        (product) =>
            product.productLockers &&
            product.productLockers.some(
                (productLocker) =>
                    productLocker.isLockerEligible &&
                    productLocker.pickupLocationId.toString() === pickupLocation.pickupLocationId
            )
    );

export const getReservedTimeOnCart = (fulfillmentTimes, cart) => {
    if (!Array.isArray(fulfillmentTimes)) {
        return null;
    }

    const fulfillmentTimeOnCart = fulfillmentTimes.find(
        (fulfillmentTime) =>
            fulfillmentTime.fulfillmentScheduleId === cart.fulfillmentId &&
            fulfillmentTime.windowStart === cart.fulfillmentWindowStart &&
            fulfillmentTime.windowEnd === cart.fulfillmentWindowEnd
    );

    const hasSlotExpired = isAfter(new Date(), new Date(cart.fulfillmentExpiration));

    if (hasSlotExpired) {
        return null;
    }

    if (fulfillmentTimeOnCart) {
        return fulfillmentTimeOnCart;
    }

    return null;
};

export const locationIsDeliveryOnly = (ecommerceStatus) => ecommerceStatus === LocationEcommerceStatus.DELIVERY_ONLY;

export const locationIsIneligible = (ecommerceStatus) => ecommerceStatus === LocationEcommerceStatus.INACTIVE;
